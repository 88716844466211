import React from "react"
import PropTypes from "prop-types"

import Box from "./box"
import { alpha } from "@theme-ui/color"

const MarkerHighlight = ({ color, children, ...restProps }) => {
  return (
    <Box
      as="span"
      {...restProps}
      sx={{
        backgroundImage: (theme) => `linear-gradient(98deg,
        ${alpha(color, 0.2)(theme)},
        ${alpha(color, 0.4)(theme)},
        ${alpha(color, 0.15)(theme)}
      )`,
        borderRadius: "7% 31% 16% 27%",
        boxDecorationBreak: "clone",
      }}
    >
      {children}
    </Box>
  )
}

MarkerHighlight.propTypes = {
  color: PropTypes.string,
}

MarkerHighlight.defaultProps = {
  color: "primary",
}

export default MarkerHighlight
