/** @jsx jsx */

import React from "react" // eslint-disable-line no-unused-vars
import { jsx, useColorMode } from "theme-ui"
import { useStaticQuery, graphql } from "gatsby"

import Container from "../../components/container"
import Stack from "../../components/stack"
import QuoteBox from "../../components/quote-box"
import Image from "../../components/image"
import Grid from "../../components/grid"
import KarteOrte from "../../images/hoelderlins-orte/karte.svg"
import KarteOrteLight from "../../images/hoelderlins-orte/karte-light.svg"

const Einfuehrung = () => {
  const [colorMode] = useColorMode()

  const data = useStaticQuery(graphql`
    {
      titelBild: file(
        relativePath: {
          eq: "hoelderlins-orte/zwingel-weg-am-hoelderlinturm.jpg"
        }
      ) {
        childImageSharp {
          gatsbyImageData(width: 600, layout: CONSTRAINED)
        }
      }
    }
  `)

  return (
    <Stack>
      <Grid columns={[1, 1, 2]}>
        <Container maxWidth="lg">
          <Image
            image={data.titelBild.childImageSharp.gatsbyImageData}
            alt="Schwarzweiß-Fotografie des Zwingels, ein schmaler Weg am Hölderlinturm"
            caption="Zwingel, Weg am Hölderlinturm, Tübingen"
          />
        </Container>
        <QuoteBox author="Martin Walser: Hölderlin auf dem Dachboden">
          Ich weiß nicht, ob es eine Herabwürdigung solcher Gedichte ist, wenn
          man sie zum Vehikel privater Erinnerung macht, ich hoffe aber, daß
          Hölderlin weniger entrüstet gewesen wäre als einer seiner Interpreten,
          wenn er mich damals beobachtet hätte, wie ich – komisch vor Ernst –
          auf alle möglichen kleinen Hügel lief und seine Gedichte wie einen
          Baedeker benützte, um die Landschaft am Bodensee kennenzulernen.
        </QuoteBox>
      </Grid>
      <Container maxWidth="3xl">
        <img
          src={colorMode === "light" ? KarteOrteLight : KarteOrte}
          alt="Stilisierte Landkarte mit allen Hölderlin-Orten"
          sx={{ width: "100%" }}
        />
      </Container>
    </Stack>
  )
}

export default Einfuehrung
