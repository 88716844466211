import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import Stack from "../../components/stack"
import QuotePrimary from "../../components/quote-primary"
import Title from "../../components/title"
import Intro from "../../components/intro"
import Paragraph from "../../components/paragraph"
import Caption from "../../components/caption"
import Grid from "../../components/grid"
import Inline from "../../components/inline"
import Heading from "../../components/heading"
import Image from "../../components/image"
import Constrain from "../../components/constrain"

const Jahreszeiten = () => {
  const data = useStaticQuery(graphql`
    query {
      jahreszeiten: file(
        relativePath: { eq: "hoelderlins-orte/jahreszeiten.jpg" }
      ) {
        ...largeImage
      }
      fruehling: file(
        relativePath: { eq: "hoelderlins-orte/jahreszeiten-fruehling.jpg" }
      ) {
        ...largeImage
      }
      sommer: file(
        relativePath: { eq: "hoelderlins-orte/jahreszeiten-sommer.jpg" }
      ) {
        ...largeImage
      }
      herbst: file(
        relativePath: { eq: "hoelderlins-orte/jahreszeiten-herbst.jpg" }
      ) {
        ...largeImage
      }
      winter: file(
        relativePath: { eq: "hoelderlins-orte/jahreszeiten-winter.jpg" }
      ) {
        ...largeImage
      }
    }
  `)

  return (
    <Stack>
      <Title top="Zyklus" middle="der" bottom="Jahreszeiten" />
      <Stack>
        <Intro>
          Hatte Hölderlin in der ersten Hälfte seines Lebens weite Fußreisen bis
          nach Frankreich und in die Schweiz unternommen und dabei Strecken von
          50 Kilometer am Tag zurückgelegt, so war der Radius seiner Wanderungen
          in den letzten 36 Jahren im Tübinger Turm am Neckar deutlich geringer:
          Die Wände seines etwa 13 Quadratmeter messenden Turmzimmers, der 42
          Schuh lange Gang des Hauses und der daran angrenzende schmale
          ›Zwingel‹ am Neckar wurden nun zu dem Raum, den er »alle Tage mit
          gewaltigen Schritten« auf- und abging.
        </Intro>
        <Paragraph>
          Die ihn umgebende Natur blieb jedoch zeitlebens Gegenstand seiner
          Betrachtungen und seines Schreibens. Wie seine Wanderungen
          beschränkten sich die Gedichte, die er im Tübinger Turm verfasste, auf
          das unmittelbar vor Augen stehende: Die Aussicht aus seinem Fenster
          und der sich davor abzeichnende Wechsel der Jahreszeiten wurden zum
          immer wieder neu variierten Thema der seiner Gedichte.
        </Paragraph>
      </Stack>
      <Stack space={6}>
        <Grid columns={[1, 2]} space={6}>
          <Image
            alt="Schwarzweiß-Fotografie von blühenden Kirschbäumen"
            image={data.fruehling.childImageSharp.gatsbyImageData}
          />
          <Image
            alt="Schwarzweiß-Fotografie eines Getreide-Feldes mit Blumen im Vordergrund"
            image={data.sommer.childImageSharp.gatsbyImageData}
          />
          <Image
            alt="Schwarzweiß-Fotografie eines gewundenen Feldweges im Nebel"
            image={data.herbst.childImageSharp.gatsbyImageData}
          />
          <Image
            alt="Schwarzweiß-Fotografie eines eingeschneiten Baumes in einer weißen Winterlandschaft"
            image={data.winter.childImageSharp.gatsbyImageData}
          />
        </Grid>
        <Caption>Frühling, Sommer, Herbst und Winter</Caption>
      </Stack>
      <Constrain align="right">
        <QuotePrimary author="Friedrich Hölderlin: Der Herbst.">
          <span>Die Bilder der Vergangenheit sind nicht verlassen</span>
          <span>Von der Natur, als wie die Tag' verblassen</span>
          <span>Im hohen Sommer, kehrt der Herbst zur Erde nieder,</span>
          <span>Der Geist der Schauer findet sich am Himmel wieder.</span>
        </QuotePrimary>
      </Constrain>
      <Constrain>
        <Stack>
          <Paragraph>
            Die sogenannten Turmgedichte schweifen nicht mehr ins Weite, sie
            verweilen an Ort und Stelle und thematisieren, was sich unmittelbar
            vor den Fenstern seines Turmzimmers abspielte. Drei Gedichte mit dem
            Titel Aussicht, neun Frühlings-, fünf Sommer-, zwei Herbst- und
            sechs Winter-Gedichte sind überliefert.
          </Paragraph>
          <Inline as="ul" alignY="center">
            <Heading as="li" color="spring" level={1}>
              9x Frühling
            </Heading>
            <Heading as="li" color="summer" level={3}>
              5x Sommer
            </Heading>
            <Heading as="li" color="autumn" level={4}>
              2x Herbst
            </Heading>
            <Heading as="li" color="winter" level={2}>
              6x Winter
            </Heading>
            <Heading as="li" color="whisper" level={5}>
              3x Aussicht
            </Heading>
          </Inline>
          <Paragraph>
            Obwohl sie vom ganz Konkreten, vor Augen stehenden ausgehen, wirken
            sie doch seltsam unkonkret: Nicht ›ein‹ Sommer, sondern ›der‹ Sommer
            schlechthin wird darin beschrieben, nicht der vor dem Fenster
            vorbeiziehende Neckar, sondern ›ein‹ Fluss, nicht die Wiesen des
            Neckartals und Wälder der schwäbischen Alb, sondern etwas
            Übertragbares, Allgemeines. Die auf der Landkarte seiner Dichtungen
            verzeichneten Koordinaten lösen sich darin in unbestimmte Bilder
            auf.
          </Paragraph>
        </Stack>
      </Constrain>
    </Stack>
  )
}

export default Jahreszeiten
