import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import Stack from "../../components/stack"
import QuotePrimary from "../../components/quote-primary"
import QuoteBox from "../../components/quote-box"
import Title from "../../components/title"
import Intro from "../../components/intro"
import Paragraph from "../../components/paragraph"
import MarkerHighlight from "../../components/marker-highlight"
import Box from "../../components/box"
import Count from "../../components/count"
import Text from "../../components/text"
import Heading from "../../components/heading"
import Image from "../../components/image"
import Constrain from "../../components/constrain"

const Perspektiven = () => {
  const data = useStaticQuery(graphql`
    query {
      schweizerBerge: file(
        relativePath: { eq: "hoelderlins-orte/schweizer-gebirgslandschaft.jpg" }
      ) {
        ...largeImage
      }
      wolken: file(relativePath: { eq: "hoelderlins-orte/wolken.jpg" }) {
        ...largeImage
      }
    }
  `)

  return (
    <Stack>
      <Title
        top="Hölderlins"
        bottom={
          <Text
            as="span"
            color="primary"
            display="block"
            align="right"
            sx={{ transform: "rotate(180deg)" }}
          >
            Perspektiven
          </Text>
        }
      />
      <Intro>
        Das Streben nach dem Unerreichbaren, dem längst Vergangenen,
        Noch-Nie-Dagewesenen oder weit Entfernten, nach der Nähe der Götter, dem
        Ruhm der Helden und der Größe der literarischen Vorbilder zählt zu den
        zentralen Motiven von Hölderlins Werk.
      </Intro>
      <Paragraph>
        In der Natur sieht er sich mit all dem verbunden, dort scheint das
        Unerreichbare greifbar. So verfolgen seine Gedichte den Adlerflug bis
        nach Asien, den Neckar bis an den griechischen Isthmus, die Bäume bis
        hinauf in ihre Wipfel, die verschneiten Berggipfel bis zu dem Punkt, an
        dem sie den Himmel berühren. Doch wird der Ausgangspunkt dabei nicht aus
        den Augen verloren. Der Blick geht stets in beide Richtungen: nach oben
        und unten, vorn und zurück, ins Ferne und Nahe.
      </Paragraph>
      <Image
        image={data.schweizerBerge.childImageSharp.gatsbyImageData}
        alt="Schwarzweiß-Fotografie verschneiter Gebirgslandschaft. Die Wolken berühren die Gipfel"
        caption="Schweizer Berge"
      />
      <Constrain align="right">
        <QuotePrimary author="Friedrich Hölderlin: An den Aether.">
          <span>und es sehnt sich auch mein Herz</span>
          <span>
            Wunderbar <MarkerHighlight>zu ihnen hinauf;</MarkerHighlight> wie
            die freundliche Heimath,
          </span>
          <span>
            Winkt es <MarkerHighlight>von oben herab,</MarkerHighlight>
          </span>
          <span>und auf die Gipfel der Alpen</span>
          <span>Möcht’ ich wandern und rufen von da dem eilenden Adler</span>
        </QuotePrimary>
      </Constrain>

      <Heading as="h3" level={2}>
        <Text as="span" color="primary">
          Himmel
        </Text>{" "}
        {` `}
        <Text as="span" color="whisper">
          Aether
        </Text>{" "}
        {` `}
        <Text as="span" color="whisper">
          himmlisch
        </Text>
      </Heading>
      <Constrain align="left">
        <Stack>
          <Paragraph>
            <Count>178</Count> Mal fällt das Wort »Himmel« in Hölderlins
            Gedichten, <Count>42</Count> Mal das Wort »Aether«,{" "}
            <Count>124</Count> Mal das Wort »himmlisch«, gefolgt von zahlreichen
            Komposita wie »himmelhoch«, »himmelnahend«, »Himmelentzückung«,
            »Himmelslicht«, »Himmelsluft«, »himmelstürmend«, »himmelwärts«.
          </Paragraph>
          <Paragraph>
            Hinaufgeblickt, -geirrt, -geklommen, -geschaut, -gestrebt,
            -gestaunt, -gestiegen und -gewandert wird insgesamt{" "}
            <Count>38</Count> Mal.
          </Paragraph>
        </Stack>
      </Constrain>
      <Box position="relative">
        <Stack>
          <Box
            position={["static", "static", "static", "static", "absolute"]}
            right={-12}
            top={-12}
            width={[null, null, "lg"]}
            zIndex={1}
          >
            <QuoteBox author="Luigi Reitani">
              Hölderlins Lyrik ist zum Teil und nicht zuletzt in ihren formalen
              Eigenschaften eine Lyrik der Schwerelosigkeit, die die Luft als
              ihr eigenes Element beschwört
            </QuoteBox>
          </Box>
          <Image
            image={data.wolken.childImageSharp.gatsbyImageData}
            caption="Wolken"
            alt="Schwarzweiß-Fotografie eines Wolken- und Lichtspiels am Himmel"
          />
        </Stack>
      </Box>
      <Heading as="h3" level={2} align="right">
        <Text as="span" color="primary">
          Erde
        </Text>{" "}
        {` `}
        <Text as="span" color="whisper">
          Tal
        </Text>{" "}
        {` `}
        <Text as="span" color="whisper">
          gefallen
        </Text>
      </Heading>
      <Constrain align="right">
        <Stack>
          <Paragraph>
            <Count>188</Count> Mal ist das Wort »Erde« in Hölderlins Gedichtwerk
            verzeichnet. <Count>26</Count> Gipfel, <Count>83</Count> Berge,{" "}
            <Count>41</Count> Gebirge und <Count>6</Count> Weinberge sind dort
            zu finden, aber auch das »Tal« wird <Count>85</Count> Mal genannt.
          </Paragraph>
          <Paragraph>
            Hinabgeblickt, gebraust, gebracht, gedacht, geeilt, gefallen,
            geglitten, geneigt, geschwungen oder -gesehen wird ganze{" "}
            <Count>96</Count> Mal.
          </Paragraph>
        </Stack>
      </Constrain>
    </Stack>
  )
}

export default Perspektiven
