import React from "react"
import Box from "./box"

const Anchor = (props) => {
  const { id, children } = props

  return (
    <Box>
      <Box
        id={id}
        display="block"
        position="relative"
        top="-120px"
        visibility="hidden"
      />
      {children}
    </Box>
  )
}

export default Anchor
