import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import AudioPlayer from "../../components/audio-player"
import Caption from "../../components/caption"
import Constrain from "../../components/constrain"
import Container from "../../components/container"
import Paragraph from "../../components/paragraph"
import Grid from "../../components/grid"
import Image from "../../components/image"
import Intro from "../../components/intro"
import QuoteBox from "../../components/quote-box"
import QuotePrimary from "../../components/quote-primary"
import Stack from "../../components/stack"
import Text from "../../components/text"
import Title from "../../components/title"

const Griechenland = () => {
  const data = useStaticQuery(graphql`
    query {
      korinth: file(
        relativePath: { eq: "hoelderlins-orte/griechenland-korinth.jpg" }
      ) {
        ...largeImage
      }
      olympia: file(
        relativePath: { eq: "hoelderlins-orte/griechenland-olympia.jpg" }
      ) {
        ...largeImage
      }
      garonne: file(relativePath: { eq: "hoelderlins-orte/die-garonne.jpg" }) {
        ...largeImage
      }
    }
  `)
  return (
    <Stack>
      <Title top="Sehnsuchtsort" bottom="Griechenland" />
      <Intro>
        Schon als Jugendlicher sehnte sich Hölderlin hinweg nach Griechenland.
        Betreten hat er dieses Land jedoch nie. Der Radius seiner Reisen
        beschränkte sich auf das Fußläufige. Seine längste Fußreise, für die er
        Strecken von bis zu 50 Kilometer am Tag zurücklegte, führte ihn von
        Nürtingen bis nach Bordeaux.
      </Intro>
      <Stack space={6}>
        <Grid columns={2} space={6}>
          <Image
            alt="Schwarzweiß-Fotografie einer Tempelruine in Korinth"
            image={data.korinth.childImageSharp.gatsbyImageData}
          />
          <Image
            alt="Schwarzweiß-Fotografie einer brüchigen Olympia-Statue"
            image={data.olympia.childImageSharp.gatsbyImageData}
          />
        </Grid>
        <Caption>Korinth und Olympia</Caption>
      </Stack>
      <Constrain align="right">
        <QuotePrimary author="Friedrich Hölderlin: Vorrede zum Hyperion">
          Von früher Jugend an lebt' ich lieber, als sonstwo, auf den Küsten von
          Jonien und Attika und den schönen Inseln des Archipelagus, und es
          gehörte unter meine liebsten Träume, einmal wirklich dahin zu wandern,
          zum heiligen Grabe der jugendlichen Menschheit. Griechenland war meine
          erste Liebe und ich weiß nicht, ob ich sagen soll, es werde meine
          lezte seyn
        </QuotePrimary>
      </Constrain>
      <Paragraph>
        Griechenland, das war für Hölderlin das Land der Lorbeerwälder und
        Pomeranzen, das Land, in dem einst die Götter weilten, der Ursprung
        aller Kultur. Dort waren einstmals all jene Ideale vorzufinden, die er
        in seiner eigenen dürftigen Zeit vermisste: Schönheit, Freiheit, ein
        Einklang zwischen Mensch und Natur. Doch war sich Hölderlin durchaus
        darüber bewusst, dass daran im gegenwärtigen Griechenland nur noch
        Ruinen erinnerten. »Aber Freund! wir kommen zu spät. Zwar leben die
        Götter, / Aber über dem Haupt droben in anderer Welt«, lautet ein Vers
        aus seiner Elegie{" "}
        <Text as="span" italic>
          Brod und Wein
        </Text>
        .
      </Paragraph>
      <Paragraph>
        Seine Romanfigur Hyperion durchwandert das Griechenland des späten 18.
        Jahrhunderts, der Zeit des griechischen Befreiungskampfs gegen die
        osmanischen Besatzer, dem sich Hyperion selbst anschließt. Nachdem er
        sich rasch ernüchtert aus dem Kriegsgeschehen zurückzieht, verlässt er
        Griechenland in Richtung Deutschland. Eine abermalige Enttäuschung. So
        endet er letztlich als Eremit und beginnt, seine Lebensgeschichte in
        Briefen an seinen Freund Bellarmin zu Papier zu bringen.
      </Paragraph>
      <Image
        image={data.garonne.childImageSharp.gatsbyImageData}
        caption="Die Garonne"
        alt="Schwarzweiß-Fotografie eines Stegs am Ufer der Garonne"
      />
      <Constrain align="right">
        <Stack>
          <QuotePrimary author="Friedrich Hölderlin: Andenken">
            <span>Der Nordost wehet,</span>
            <span>Der liebste unter den Winden</span>
            <span>Mir, weil er feurigen Geist</span>
            <span>Und gute Fahrt verheißet den Schiffern.</span>
            <span>Geh aber nun und grüße</span>
            <span>Die schöne Garonne,</span>
            <span>Und die Gärten von Bourdeaux</span>
            <span>Dort, wo am scharfen Ufer</span>
            <span>Hingehet der Steg...</span>
          </QuotePrimary>
          <AudioPlayer
            src={`sonderausstellungen/hoelderlins-orte/andenken`}
            title={
              <Text as="span" sans>
                Christian Reiner liest{" "}
                <Text bold as="strong">
                  ›Andenken‹
                </Text>
              </Text>
            }
          />
        </Stack>
      </Constrain>
      <Paragraph>
        Im Dezember 1801 bricht Hölderlin zu einer neuen Hauslehrer-Stelle in
        Frankreich auf. Über das, was er dort erlebt hat, ist nicht viel
        überliefert. Das Gedicht Andenken aber, womöglich bereits vor Ort
        entworfen und im Frühjahr des Folgejahres vollendet, hat einen
        Ausschnitt dessen festgehalten, was er dort erblickt hat. Möglicherweise
        hat Hölderlin hier in Soulac-sur-Mer zum ersten und einzigen Mal in
        seinem Leben das Meer gesehen, das er in seinen Gedichten so oft
        imaginiert hatte.
      </Paragraph>
      <Container maxWidth="2xl">
        <QuoteBox author="Thomas Knubben">
          Die Pointe de Grave [an der Mündung der Gironde ins offene Meer] ist
          der entfernteste geographische Ort, den Hölderlin auf der
          exzentrischen Bahn seines Lebens erreicht haben dürfte. Der Endpunkt
          der von ihm erlebten Welt. Finis terrae.
        </QuoteBox>
      </Container>
    </Stack>
  )
}

export default Griechenland
