import React from "react"
import Text from "./text"

const Intro = (props) => {
  const { children, ...restProps } = props

  return (
    <Text
      size={[3, 4, 5, 5, 6]}
      lang="de"
      hyphens="auto"
      bold
      sans
      {...restProps}
    >
      {children}
    </Text>
  )
}

export default Intro
